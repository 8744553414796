import request from '@/utils/request'


// 查询分销商列表
export function listDistributor(query) {
  return request({
    url: '/user/user-distributors/list',
    method: 'get',
    params: query
  })
}

// 查询分销商分页
export function pageDistributor(query) {
  return request({
    url: '/user/user-distributors/page',
    method: 'get',
    params: query
  })
}

export function pageDistributors(query) {
  return request({
    url: '/user/user-distributors/pageDistributorData',
    method: 'get',
    params: query
  })
}

export function pageDistributors1(query) {
  return request({
    url: '/user/user-distributors/pageDistributorData1',
    method: 'get',
    params: query
  })
}

export function changeStatus(data) {
  return request({
    url: '/user/user-distributors/changeStatus',
    method: 'post',
    data: data
  })
}


// 查询分销商详细
export function getDistributor(data) {
  return request({
    url: '/user/user-distributors/detail',
    method: 'get',
    params: data
  })
}

export function getBankList(data) {
  return request({
    url: '/user/user-distributors/getBankList',
    method: 'get',
    params: data
  })
}

export function getChildBankList(data) {
  return request({
    url: '/user/user-distributors/getChildBankList',
    method: 'get',
    params: data
  })
}

// 新增分销商
export function addDistributor(data) {
  return request({
    url: '/user/user-distributors/add',
    method: 'post',
    data: data
  })
}

// 修改分销商
export function updateDistributor(data) {
  return request({
    url: '/user/user-distributors/edit',
    method: 'post',
    data: data
  })
}

export function getUserLists(data) {
  return request({
    url: '/biz/users/getUserLists',
    method: 'get',
    params: data
  })
}


// 删除分销商
export function delDistributor(data) {
  return request({
    url: '/user/user-distributors/delete',
    method: 'post',
    data: data
  })
}
